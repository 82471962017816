import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { API_URL } from "../../config";

//material ui
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "./AdminPanel.css";

const AdminPanel = ({ userId, onLogout, setPinValues, isAdmin }) => {
  const [selectedTable, setSelectedTable] = useState(null);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [showDesksList, setShowDesksList] = useState(false);
  const [testDate, setTestDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const lokalizacja1 = localStorage.getItem("lokalizacja1");
  const lokalizacja2 = localStorage.getItem("lokalizacja2");
  const lokalizacja3 = localStorage.getItem("lokalizacja3");
  const lokalizacja4 = localStorage.getItem("lokalizacja4");

  const locationsMap = {
    Kraków: lokalizacja1 === "1",
    Warszawa: lokalizacja2 === "1",
    Wrocław: lokalizacja3 === "1",
    Poznań: lokalizacja4 === "1",
  };

  const availableLocations = Object.keys(locationsMap).filter(
    (location) => locationsMap[location]
  );
  const [testPlace, setTestPlace] = useState(availableLocations[0]);

  const location = useLocation();
  const tableFromURL = new URLSearchParams(location.search).get("table");
  const [activeTiles, setActiveTiles] = useState([]);

  const [summaryData, setSummaryData] = useState({
    totalReservations: 0, // Całkowita liczba rezerwacji
    confirmedReservations: 0, // Liczba potwierdzonych rezerwacji
    unconfirmedReservations: 0, // Liczba niepotwierdzonych rezerwacji
    deskReservations: 0, // Liczba zarezerwowanych biurek
    confirmedDeskReservations: 0, // Liczba potwierdzonych rezerwacji biurek
    unconfirmedDeskReservations: 0, // Liczba niepotwierdzonych rezerwacji biurek
    desksList: [], // Lista zarezerwowanych biurek
    garageReservations: 0, // Liczba zarezerwowanych garaży
    confirmedGarageReservations: 0, // Liczba potwierdzonych rezerwacji garaży
    unconfirmedGarageReservations: 0, // Liczba niepotwierdzonych rezerwacji garaży
    garagesList: [], // Lista zarezerwowanych garaży
    usersWhoReserved: [], // Lista użytkowników, którzy dokonali rezerwacji
  });

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  let futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 7);
  let future_dd = String(futureDate.getDate()).padStart(2, "0");
  let future_mm = String(futureDate.getMonth() + 1).padStart(2, "0");
  let future_yyyy = futureDate.getFullYear();
  futureDate = future_yyyy + "-" + future_mm + "-" + future_dd;

  const toggleTile = (tile) => {
    if (activeTiles.includes(tile)) {
      setActiveTiles((prev) => prev.filter((t) => t !== tile));
    } else {
      setActiveTiles((prev) => [...prev, tile]);
    }
  };

  const fetchSummary = useCallback(async (dateToFetch, placeToFetch) => {
    try {
      const formData = new FormData();
      formData.append("date", dateToFetch);
      formData.append("place", placeToFetch);

      const response = await axios.post(
        API_URL + "/getAvailableDesks",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setSummaryData(response.data);
      }
    } catch (error) {
      console.error("Error during fetching summary:", error);
    }
  }, []);

  useEffect(() => {
    if (tableFromURL == "users") {
      setSelectedTable(tableFromURL);
      fetchUsers(userId);
    } else if (tableFromURL == "reservations") {
      setSelectedTable(tableFromURL);
      fetchDesks(userId);
      fetchReservations(userId);
    } else if (tableFromURL == "desks") {
      setSelectedTable(tableFromURL);
      fetchDesks(userId);
    } else if (tableFromURL == "summary") {
      setSelectedTable(tableFromURL);

      setTestDate(today);
      setTestPlace(availableLocations[0]);
      fetchSummary(testDate, testPlace);
    }
  }, [tableFromURL]);

  const handleRowClick = (userId) => {
    navigate(`/userEdit/${userId}`);
  };

  const handleReservationRowClick = (id) => {
    navigate(`/reservationEdit/${id}`);
  };

  const handleDesksRowClick = (did) => {
    navigate(`/BiurkoEdit/${did}`);
  };

  const handleAddClick = (userId) => {
    navigate(`/userAdd`);
  };
  const handleDeskAddClick = (userId) => {
    navigate(`/deskAdd`);
  };

  const fetchUsers = useCallback(async (userId) => {
    try {
      const formData = new FormData();
      formData.append("userId", userId);
      const response = await axios.post(API_URL + "/getUsers", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        setTableData(response.data);
      }
    } catch (error) {
      console.error("Error during reserved desks fetching:", error);
    }
  }, []);

  const fetchReservations = useCallback(async (userId) => {
    try {
      const formData = new FormData();
      formData.append("userId", userId);
      const response = await axios.post(
        API_URL + "/getReservations",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setTableData(response.data);
      }
    } catch (error) {
      console.error("Error during reserved desks fetching:", error);
    }
  }, []);

  const fetchDesks = useCallback(async (userId) => {
    try {
      const formData = new FormData();
      formData.append("userId", userId);
      const response = await axios.post(API_URL + "/getDesksAdmin", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      //console.log("Desks:", response.data);

      if (response.data) {
        setTableData(response.data);
      }
    } catch (error) {
      console.error("Error during reserved desks fetching:", error);
    }
  }, []);

  const handleLogout = () => {
    onLogout();
    setPinValues(Array(4).fill(""));
    localStorage.removeItem("isLoggedIn");
  };

  const handleSelectUsersTable = () => {
    navigate("/adminPanel?table=users");

    setSelectedTable("users");
    fetchUsers(userId);
  };

  const handleSelectReservationsTable = () => {
    navigate("/adminPanel?table=reservations");

    setSelectedTable("reservations");
    fetchReservations(userId);
  };

  const handleSelectDesksTable = () => {
    navigate("/adminPanel?table=desks");
    setSelectedTable("desks");
    fetchDesks(userId);
  };

  const handleSummary = () => {
    navigate("/adminPanel?table=summary");
    setSelectedTable("summary");
    setTestDate(today);
    setTestPlace(availableLocations[0]);
    fetchSummary(today, testPlace);
  };

  if (!isAdmin) {
    return (
      <div className="noPermissions">
        Nie masz uprawnień do przeglądania tej strony.
      </div>
    );
  }

  return (
    <div>

<button className="qr-button" onClick={() => navigate(`/qrtest`)}>
        Kody QR
      </button>
      <button className="home-button" onClick={() => navigate(`/home`)}>
        Home
      </button>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
      <div className="HomePageContainer">
      <div className="admin-menu-wrapper">

        <div className="admin-menu">
          <button
            onClick={handleSummary}
            className={
              selectedTable === "summary" ? "admin-menu-button-selected" : ""
            }
          >
            <SupervisedUserCircleIcon className="admin-menu-icon" />{" "}
            Podsumowanie
          </button>
          <button
            onClick={handleSelectUsersTable}
            className={
              selectedTable === "users" ? "admin-menu-button-selected" : ""
            }
          >
            <SupervisedUserCircleIcon className="admin-menu-icon" /> Użytkownicy
          </button>

          <button
            onClick={() => handleSelectReservationsTable()}
            className={
              selectedTable === "reservations"
                ? "admin-menu-button-selected"
                : ""
            }
          >
            <EventAvailableIcon className="admin-menu-icon" /> Rezerwacje
          </button>

          <button
            onClick={() => handleSelectDesksTable()}
            className={
              selectedTable === "desks" ? "admin-menu-button-selected" : ""
            }
          >
            <TableRestaurantIcon className="admin-menu-icon" /> Biurka
          </button>
        </div>
        </div>
        {selectedTable === "summary" && (
          <div className="summary-container">
            <div className="top-admin-bar">
              <label className="pasek2">
                <span className="choose-date">Wybierz datę:</span>
                <input
                  className="date-input admindate"
                  type="date"
                  min={today}
                  max={futureDate}
                  value={testDate}
                  onChange={(r) => {
                    const selectedDate = r.target.value;
                    setTestDate(selectedDate);
                    fetchSummary(selectedDate, testPlace);
                  }}
                />
              </label>

              <select
                className="miejsce"
                onChange={(r) => {
                  const selectedPlace = r.target.value;
                  setTestPlace(selectedPlace);
                  fetchSummary(testDate, selectedPlace);
                }}
              >
                {availableLocations.map((location) => (
                  <option key={location}>{location}</option>
                ))}
              </select>
            </div>
            <div className="gorne2">
              <div className="tile">
                <div className="tile-info">
                  <span className="tile-text">Zajęte biurka</span>
                  <span className="tile-number">
                    {summaryData.deskReservationPercentage}%
                  </span>
                </div>
              </div>

              <div className="tile">
                <div className="tile-info">
                  <span className="tile-text">Zajęte miejsca parkingowe</span>
                  <span className="tile-number">
                    {summaryData.garageReservationPercentage}%
                  </span>
                </div>
              </div>
            </div>

            <div className="resztaKafelkow">
              <div
                className={`tile ${
                  activeTiles.includes("reservedPlaces") ? "active" : ""
                }`}
                onClick={() => toggleTile("reservedPlaces")}
              >
                <div className="tile-info">
                  <span className="tile-text">
                    Aktualnie zarezerwowane miejsca
                  </span>
                  <span className="tile-number">
                    {summaryData.totalReservations}
                  </span>
                </div>

                {activeTiles.includes("reservedPlaces") ? (
                  <div>
                    <div>
                      {summaryData.reservedPlaces[0] &&
                        summaryData.reservedPlaces.length > 0 && (
                          <table className="tileData">
                            <thead>
                              <tr>
                                <th>Miejsce</th>
                                <th>PIN</th>
                              </tr>
                            </thead>
                            <tbody>
                              {summaryData.reservedPlaces.map((data, index) => (
                                <tr key={index}>
                                  <td>{data.name}</td>
                                  <td>{data.pin}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                    </div>

                    <div className="arrow">
                      <KeyboardArrowUpIcon />
                    </div>
                  </div>
                ) : (
                  <div className="arrow">
                    <KeyboardArrowDownIcon />
                  </div>
                )}
              </div>

              <div
                className={`tile ${
                  activeTiles.includes("confirmedDeskReservations")
                    ? "active"
                    : ""
                }`}
                onClick={() => toggleTile("confirmedDeskReservations")}
              >
                <div className="tile-info">
                  <span className="tile-text">Potwierdzone rezerwacje</span>
                  <span className="tile-number">
                    {summaryData.confirmedDeskReservations +
                      summaryData.confirmedGarageReservations}
                  </span>
                </div>
                {activeTiles.includes("confirmedDeskReservations") ? (
                  <div>
                    {summaryData.confirmedData[0] &&
                      summaryData.confirmedData.length > 0 && (
                        <table className="tileData">
                          <thead>
                            <tr>
                              <th>PIN</th>
                              <th>Miejsce</th>
                            </tr>
                          </thead>
                          <tbody>
                            {summaryData.confirmedData.map((data, index) => (
                              <tr key={index}>
                                <td>{data.pin}</td>
                                <td>{data.place}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}

                    <div className="arrow">
                      <KeyboardArrowUpIcon />
                    </div>
                  </div>
                ) : (
                  <div className="arrow">
                    <KeyboardArrowDownIcon />
                  </div>
                )}
              </div>

              <div
                className={`tile ${
                  activeTiles.includes("notConfirmedReservations")
                    ? "active"
                    : ""
                }`}
                onClick={() => toggleTile("notConfirmedReservations")}
              >
                <div className="tile-info">
                  <span className="tile-text">
                    Liczba niepotwierdzonych rezerwacji
                  </span>
                  <span className="tile-number">
                    {summaryData.notConfirmedReservations}
                  </span>
                </div>
                {activeTiles.includes("notConfirmedReservations") ? (
                  <div>
                    {summaryData.reservedPlaces[0] &&
                      summaryData.reservedPlaces.length > 0 && (
                        <table className="tileData">
                          <thead>
                            <tr>
                              <th>PIN</th>
                              <th>Miejsce</th>
                            </tr>
                          </thead>
                          <tbody>
                            {summaryData.notConfirmedData.map((data, index) => (
                              <tr key={index}>
                                <td>{data.pin}</td>
                                <td>{data.place}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    <div className="arrow">
                      <KeyboardArrowUpIcon />
                    </div>
                  </div>
                ) : (
                  <div className="arrow">
                    <KeyboardArrowDownIcon />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {selectedTable === "users" && (
          <p>
            Tabela użytkowników
            <button onClick={() => handleAddClick()} className="add-user">
              <PersonAddAlt1Icon />
            </button>
          </p>
        )}
        {selectedTable === "reservations" && <p>Tabela rezerwacji</p>}
        {selectedTable === "desks" && (
          <p>
            Tabela biurek
            <button onClick={() => handleDeskAddClick()} className="add-user">
              <AddCircleIcon />
            </button>
          </p>
        )}
      </div>

      <div>
        {selectedTable === "users" && tableData.length > 0 && (
          <div className="table-container">
            <table className="gradient-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>PIN</th>
                  <th>Aktywny</th>
                  <th>Kraków</th>
                  <th>Warszawa</th>
                  <th>Wrocław</th>
                  <th>Poznań</th>
                  <th>Garaż</th>
                  <th>Weekend</th>
                  <th>Biurko</th>
                  <th>Administrator</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr
                    className="wiersz"
                    key={row.uid}
                    onClick={() => handleRowClick(row.uid)}
                  >
                    <td>{row.id}</td>
                    <td>{row.pin}</td>
                    <td>{row.aktywny}</td>
                    <td>{row.lokalizacja1 ? "Tak" : "Nie"}</td>
                    <td>{row.lokalizacja2 ? "Tak" : "Nie"}</td>
                    <td>{row.lokalizacja3 ? "Tak" : "Nie"}</td>
                    <td>{row.lokalizacja4 ? "Tak" : "Nie"}</td>

                    <td>{row.garaz ? "Tak" : "Nie"}</td>
                    <td>{row.weekend ? "Tak" : "Nie"}</td>
                    <td>{row.biurko ? "Tak" : "Nie"}</td>
                    <td>{row.isAdmin ? "Tak" : "Nie"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {selectedTable === "reservations" && tableData.length > 0 && (
          <div className="table-container">
            <table className="gradient-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NAZWA</th>
                  <th>Rodzaj</th>
                  <th>Data rezerwacji</th>
                  <th>ID użytkownika</th>
                  <th>Potwierdzenie</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr
                    className="wiersz"
                    key={row.historia_id}
                    onClick={() => handleReservationRowClick(row.historia_id)}
                  >
                    <td>{row.historia_id}</td>
                    <td>{row.nazwa}</td>
                    <td>{row.rodzaj}</td>
                    <td>{row.data_rezerwacji}</td>
                    <td>{row.uzytkownik}</td>
                    <td>{row.potwierdzenie ? "Tak" : "Nie"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {selectedTable === "desks" && tableData.length > 0 && (
          <div className="table-container">
            <table className="gradient-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>did</th>
                  <th>lokalizacja</th>
                  <th>nazwa</th>
                  <th>rodzaj</th>
                  <th>Użytkownicy (ID:PIN)</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr
                    className="wiersz"
                    key={row.did}
                    onClick={() => handleDesksRowClick(row.did)}
                  >
                    <td>{row.id}</td>
                    <td>{row.did}</td>
                    <td>
                      {{
                        1: "Kraków",
                        2: "Warszawa",
                        3: "Wrocław",
                        4: "Poznań",
                      }[row.lokalizacja] || "Nieznane"}
                    </td>
                    <td>{row.nazwa}</td>
                    <td>{row.rodzaj}</td>
                    <td>
                      {row.user_ids && row.user_pins
                        ? row.user_ids.split(",").map((id, index) => (
                            <span key={index}>
                              {id}(PIN:{row.user_pins.split(",")[index]})<br />
                            </span>
                          ))
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPanel;

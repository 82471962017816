import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import Top from "./components/Top";

import { API_URL } from "./config";

import UserEdit from "./components/AdminPanel/UserEdit";
import UserAdd from "./components/AdminPanel/UserAdd";
import DeskAdd from "./components/AdminPanel/DeskAdd";
import ReservationEdit from "./components/AdminPanel/ReservationEdit";
import BiurkoEdit from "./components/AdminPanel/BiurkoEdit";

import QrLogin from "./components/QrSystem/QrLogin";
import QrTest from "./components/QrSystem/QrTest";
import QrConfirmation from "./components/QrSystem/QrConfirmation";

function App() {
  const InnerApp = () => {
    const navigate = useNavigate();

    const [pin, setPin] = useState(Array(4).fill(""));
    const [userId, setUserId] = useState(localStorage.getItem("userId") || "-");
    const [errorMessage, setErrorMessage] = useState("");
    const [isAdmin, setIsAdmin] = useState("");
    const [ifDeskReserved, setIfDeskReserved] = useState("");
    const [reservedDeskModal, setReservedDeskModal] = useState("");
    const [reservedDeskModalMessage, setReservedDeskModalMessage] = useState("");

    const [pinValues, setPinValues] = useState(Array(4).fill(""));
    const [isLoggedIn, setIsLoggedIn] = useState(
      localStorage.getItem("isLoggedIn") === "true"
    );
    const [qrDid, setQrDid] = useState("");

    const handlePinChange = (value, index) => {
      setPin((prevPin) => {
        const newPin = [...prevPin];
        newPin[index] = value;
        return newPin;
      });
    };

    const verifyPin = async (pin) => {
      try {
        const formData = new FormData();
        formData.append("pin", pin);
        const response = await axios.post(API_URL + "/verifyPin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        return {
          isPinValid: response.data.isPinValid,
          uid: response.data.uid,
          isAdmin: response.data.isAdmin,
          lokalizacja1: response.data.lokalizacja1,
          lokalizacja2: response.data.lokalizacja2,
          lokalizacja3: response.data.lokalizacja3,
          lokalizacja4: response.data.lokalizacja4,
        };
      } catch (error) {
        console.error("Error during PIN verification:", error);
        return {
          isPinValid: false,
          uid: null,
          isAdmin: null,
          lokalizacja1: null,
          lokalizacja1: null,
          lokalizacja1: null,
          lokalizacja1: null,
        };
      }
    };

    const handleSubmit = async () => {
      try {
        const pinString = pin.join("");

        if (pinString.length < 4) {
          setErrorMessage("Proszę wypełnić wszystkie pola PIN.");
          return;
        }
        const pinInt = parseInt(pinString, 10);

        const {
          isPinValid,
          uid,
          isAdmin,
          lokalizacja1,
          lokalizacja2,
          lokalizacja3,
          lokalizacja4,
        } = await verifyPin(pinInt);
        if (isPinValid) {
          setIsLoggedIn(true);
          setUserId(uid);
          setIsAdmin(isAdmin);
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("userId", uid);
          localStorage.setItem("pin", pinInt);
          localStorage.setItem("lokalizacja1", lokalizacja1);
          localStorage.setItem("lokalizacja2", lokalizacja2);
          localStorage.setItem("lokalizacja3", lokalizacja3);
          localStorage.setItem("lokalizacja4", lokalizacja4);
        } else {
          setErrorMessage("PIN jest niepoprawny.");
          setPinValues(Array(4).fill(""));
        }
      } catch (error) {
        console.error("Error during PIN verification:", error);
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const pinInt = localStorage.getItem("pin");
          const { isPinValid, uid, isAdmin } = await verifyPin(pinInt);
          if (isPinValid) {
            setIsLoggedIn(true);
            setUserId(uid);
            setIsAdmin(isAdmin);
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("userId", uid);
          }
        } catch (error) {
          console.error("Error during PIN verification:", error);
        }
      };
      const currentPath = window.location.pathname;

      if (isLoggedIn && currentPath === "/login") {
        navigate("/home");
      } else if (isLoggedIn && currentPath === "/") {
        navigate("/home");
      } else if (currentPath.startsWith("/qr/")) {
      } else if (currentPath.startsWith("/qrtest")) {
      } else if (currentPath.startsWith("/qrconfirmation")) {
      } else if (!isLoggedIn && currentPath !== "/login") {
        navigate("/login");
      }

      if (!isAdmin) {
        fetchData();
      }
    }, [isLoggedIn, navigate]);

    return (
      <div>
        <Top />
        <Routes>
          <Route
            path="/login"
            element={
              <LoginPage
                onPinChange={handlePinChange}
                onSubmit={handleSubmit}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                pinValues={pinValues}
                setPinValues={setPinValues}
              />
            }
          />
          <Route
            path="/home"
            element={
              <HomePage
                userId={userId}
                onLogout={() => setIsLoggedIn(false)}
                setUserId={setUserId}
                pinValues={pinValues}
                setPinValues={setPinValues}
                isAdmin={isAdmin}
                setIsAdmin={setIsAdmin}
                ifDeskReserved={ifDeskReserved}
                setIfDeskReserved={setIfDeskReserved}
                reservedDeskModal={reservedDeskModal}
                setReservedDeskModal={setReservedDeskModal}
                reservedDeskModalMessage={reservedDeskModalMessage}
                setReservedDeskModalMessage={setReservedDeskModalMessage}
              />
            }
          />
          <Route
            path="/adminPanel"
            element={
              <AdminPanel
                userId={userId}
                onLogout={() => setIsLoggedIn(false)}
                setUserId={setUserId}
                pinValues={pinValues}
                setPinValues={setPinValues}
                isAdmin={isAdmin}
                setIsAdmin={setIsAdmin}
              />
            }
          />
          <Route
            path="/userEdit/:userId"
            element={<UserEdit isAdmin={isAdmin} setIsAdmin={setIsAdmin} adminId={userId} />}
          />
          <Route
            path="/userAdd"
            element={<UserAdd isAdmin={isAdmin} setIsAdmin={setIsAdmin} />}
          />
          <Route
            path="/ReservationEdit/:id"
            element={
              <ReservationEdit isAdmin={isAdmin} setIsAdmin={setIsAdmin} />
            }
          />
          <Route
            path="/BiurkoEdit/:did"
            element={
              <BiurkoEdit
                isAdmin={isAdmin}
                setIsAdmin={setIsAdmin}
                userId={userId}
              />
            }
          />
          <Route
            path="/deskAdd"
            element={<DeskAdd isAdmin={isAdmin} setIsAdmin={setIsAdmin} />}
          />
          <Route
            path="/qr/:did"
            element={
              <QrLogin
                onPinChange={handlePinChange}
                onSubmit={handleSubmit}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                pinValues={pinValues}
                setPinValues={setPinValues}
                qrDid={qrDid}
                setQrDid={setQrDid}
              />
            }
          />
          <Route
            path="/qrconfirmation"
            element={
              <QrConfirmation
                onPinChange={handlePinChange}
                onSubmit={handleSubmit}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                pinValues={pinValues}
                setPinValues={setPinValues}
                qrDid={qrDid}
                setQrDid={setQrDid}
                ifDeskReserved={ifDeskReserved}
                setIfDeskReserved={setIfDeskReserved}
                reservedDeskModal={reservedDeskModal}
                setReservedDeskModal={setReservedDeskModal}
                reservedDeskModalMessage={reservedDeskModalMessage}
                setReservedDeskModalMessage={setReservedDeskModalMessage}
              />
            }
          />
          <Route path="/qrtest" element={<QrTest userId={userId} isAdmin={isAdmin} />} />
        </Routes>
      </div>
    );
  };
  return (
    <Router>
      <InnerApp />
    </Router>
  );
}

export default App;

// UserEdit.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';
import "./UserEdit.css";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UserDelete from "./UserDelete";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { v4 as uuidv4 } from 'uuid';
import Confirmation from "../Modals/Confirmation";



const UserAdd = (isAdmin) => {
    const { userId } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const generatePin = () => {
        return Math.floor(1000 + Math.random() * 9000).toString();
    };

    const [errors, setErrors] = useState({
        pin: "",
        aktywny: ""
      });
    
      const [userData, setUserData] = useState(() => ({
        uid: uuidv4(),
        pin: generatePin(),
        aktywny: 0,
        lokalizacja1: 0,
        lokalizacja2: 0,
        lokalizacja3: 0,
        lokalizacja4: 0,
        garaz: 0,
        weekend: 0,
        biurko: 0,
        isAdmin: 0
    }));
    
   
    const navigate = useNavigate();


    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: type === "checkbox" ? (checked ? 1 : 0) : value

        }));
    };

   
const handleSubmit = async (event) => {
    event.preventDefault();
  
    let errorMessages = {
      pin: "",
      aktywny: ""
    };
  
    if (!userData.pin || userData.pin.length !== 4) {
        errorMessages.pin = "PIN musi składać się z 4 cyfr.";
    }
    
    if (userData.aktywny === "" || userData.aktywny == null) {
      errorMessages.aktywny = 'Pole "Aktywny" jest wymagane.';
    }
  
    if (errorMessages.pin || errorMessages.aktywny) {
      setErrors(errorMessages);
      return;
    }
        event.preventDefault();
    
        try {
            const formData = new FormData();
            console.log(userId);
            for (let key in userData) {
                console.log(key, userData[key])
                formData.append(key, userData[key]);
            }

            const response = await axios.post(
              API_URL + "/userAdd",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
    
            if (response.data && response.data.success) {
                //alert(response.data.message);
                afterAdd(response.data.message);

                //navigate(`/adminPanel?table=users`);

            } else {
                
            
                //alert(response.data.message);
                afterAdd(response.data.message);
            }
        } catch (error) {
            console.error("Error updating user data:", error);
            alert('Wystąpił błąd podczas aktualizacji danych. Proszę spróbować później.');
        }
    };
    
    const afterAdd = (message) => {
        setModalMessage(message);
        setIsModalVisible(true);
        document.body.style.overflow = "hidden";
    };

    if (!isAdmin['isAdmin']) {
        return <div className='noPermissions'>Nie masz uprawnień do przeglądania tej strony.</div>;
    }
    return (
<div className='testt'>
<div className='backbutton' onClick={() => navigate(`/adminPanel?table=users`)}>
        <ArrowBackIcon/>
        </div>
        <div className="user-edit-container">
            <h2>Dodawanie użytkownika</h2>

            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <label>UID</label>
                    <input type="text" name="uid" value={userData.uid} onChange={handleChange} disabled />
                </div>
                <div className="input-group">
      <label>PIN</label>
      {errors.pin && <div className="error-message">{errors.pin}</div>}
      <input 
        type="text" 
        name="pin" 
        value={userData.pin} 
        onChange={handleChange} 
        pattern="\d{4}" 
        title="PIN powinien składać się z dokładnie 4 cyfr" 
        required 
      />
    </div>
                <div className="input-group">
      <label>Aktywny</label>
      {errors.aktywny && <div className="error-message">{errors.aktywny}</div>}
      <input type="number" name="aktywny" value={userData.aktywny} onChange={handleChange} min="0" max="4" required />
    </div>


    <div className="input-group">

    <label>
                <input type="checkbox" name="lokalizacja1" checked={userData.lokalizacja1} onChange={handleChange} />
                Kraków
            </label>
            </div>

            <div className="input-group">

            <label>
                <input type="checkbox" name="lokalizacja2" checked={userData.lokalizacja2} onChange={handleChange} />
                Warszawa
            </label>
            </div>
            <div className="input-group">

            <label>
                <input type="checkbox" name="lokalizacja3" checked={userData.lokalizacja3} onChange={handleChange} />
                Wrocław
            </label>
            </div>
            <div className="input-group">

            <label>
                <input type="checkbox" name="lokalizacja4" checked={userData.lokalizacja4} onChange={handleChange} />
                Poznań
            </label>
            </div>
            <div className="input-group">

            <label>
                <input type="checkbox" name="biurko" checked={userData.biurko} onChange={handleChange} />
                Biurko
            </label>
            </div>
            <div className="input-group">

            <label>
                <input type="checkbox" name="garaz" checked={userData.garaz} onChange={handleChange} />
                Garaż
            </label>
            </div>

    <div className="input-group">

            <label>
                <input type="checkbox" name="weekend" checked={userData.weekend} onChange={handleChange} />
                Weekend
            </label>

                </div>
                <button type="submit">Zapisz zmiany</button>
            </form>
        </div>

        {isModalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <Confirmation
                            message={modalMessage}
                            onConfirm={() => {

                                setIsModalVisible(false);
                                document.body.style.overflow = "auto";
                                navigate(`/adminPanel?table=users`);

                            }}
                            onClose={() => {
                                setIsModalVisible(false);
                                document.body.style.overflow = "auto";
                                navigate(`/adminPanel?table=users`);

                            }}
                        />
                    </div>
                </div>
            )}
        </div>

        
    );
};

export default UserAdd;

import React, { useRef, useEffect } from "react";
import "./Confirmation.css";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';const Confirmation = ({ message, onClose }) => {
  
  const containerRef = useRef(null);

  useEffect(() => {
    containerRef.current.focus();
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onClose();
    }
  };

  return (
    <div 
      className="confirmation-container" 
      tabIndex="0" 
      onKeyDown={handleKeyDown}
      ref={containerRef}
    >

      
{message === "Zarezerwowano pomyślnie." || message === "Zmodyfikowano rekord." || message ==="Użytkownik dodany." || message === "Usunięto." || message==="Miejsce dodane." ? (
             <CheckCircleIcon fontSize="large"/>
       

):(
<CancelIcon fontSize="large"/>


)}
      <p className="message">{message}</p>
      <button className="close-button2" onClick={onClose}>Close</button>
    </div>
  );
};

export default Confirmation;

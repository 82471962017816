import React, { useState, useEffect } from "react";
import axios from "axios";
import { useRef } from "react";
import "../QrSystem/QrStyles.css";
import { API_URL } from "../../config";
import { useNavigate } from "react-router-dom";

import "../Modals/Confirmation";
import "../Modals/ReservationConfirm";

//material ui
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";

const QrConfirmation = ({ qrDid, ifDeskReserved, setIfDeskReserved, reservedDeskModal, setReservedDeskModal, setReservedDeskModalMessage }) => {

  const navigate = useNavigate(); // Zwraca funkcję do nawigacji

  const [isDeskAvailable, setIsDeskAvailable] = useState(null);

  const [response, setResponse] = useState({
    nazwa: "",
    lokalizacja: "",
    rodzaj: null,
    did: "",
    dostepnosc: null,
  });

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  const [showH1, setShowH1] = useState(false);
  const [showH2, setShowH2] = useState(false);
  const [showH3, setShowH3] = useState(false);

  const debounceTimeout = useRef(null);

  const did = qrDid;
  console.log(did);
  useEffect(() => {
    const checkAvailability = async () => {
      try {
        const formData = new FormData();
        formData.append("did", did);
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0");
        let yyyy = today.getFullYear();
        today = yyyy + "-" + mm + "-" + dd;

        formData.append("date", today);
        formData.append("userId", localStorage.getItem("userId"));

        const response = await axios.post(
          API_URL + "/checkDeskAvailability",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(response.data)
        if (response.data) {

          if(response.data.error)
          {
            setReservedDeskModal(true);
            setReservedDeskModalMessage(response.data.error);
            navigate("/home");

          }
          else if (response.data.dostepnosc) {
            setResponse(response.data);
            setIsDeskAvailable(response.data.dostepnosc);

            
          }else if(!response.data.dostepnosc)
            {
              setIfDeskReserved(response.data.did);
              setReservedDeskModal(true);
              setReservedDeskModalMessage("Biurko jest już zarezerwowane, jednak możesz je zarezerwować na inny dzień. Miejsce, które próbowałeś zarezerwować jest wyświetlane na liście w kolorze zielonym.")
              navigate("/home")
            }
        }
      } catch (error) {
        console.error("Error during PIN verification:", error);
        return {
          isPinValid: false,
          uid: null,
          isAdmin: null,
          lokalizacja1: null,
          lokalizacja1: null,
          lokalizacja1: null,
          lokalizacja1: null,
        };
      }
    };

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      checkAvailability();
    }, 200);

    return () => {};
  }, [did]);

  const QrReserve = async (param) => {
    try {
      const formData = new FormData();
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      formData.append("date", today);
      formData.append("userId", localStorage.getItem("userId"));
      formData.append("did", did);
      formData.append("param", parseInt(param));

      const response = await axios.post(API_URL + "/QrReserve", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
      }
    } catch (error) {
      console.error("Error during PIN verification:", error);
      return {
        isPinValid: false,
        uid: null,
        isAdmin: null,
        lokalizacja1: null,
        lokalizacja1: null,
        lokalizacja1: null,
        lokalizacja1: null,
      };
    }
  };
  const onSubmit = (yesorno) => {
    console.log(yesorno);
    if (yesorno === 2) {

      setReservedDeskModal(true);
      setReservedDeskModalMessage("Ok, miłego dnia.");
      navigate("/home");

      //setShowH1(true);
    } else if (yesorno === 1) {
      const result = QrReserve(1);
      if (result) {
        navigate("/home");
        //setShowH2(true);
      }
    } else if (yesorno === 3) {
      const result = QrReserve(2);
      if (result) {
        navigate("/home");

        //setShowH3(true);
      }
    }
  };

  if (showH1) {
    return (
      <div className="confirmation-container2">
        <h1>Ok, miłego dnia.</h1>
      </div>
    );
  }

  if (showH2) {
    return (
      <div className="confirmation-container2">
        <h1>Zarezerwowano i potwierdzono przybycie.</h1>
      </div>
    );
  }

  if (showH3) {
    return (
      <div className="confirmation-container2">
        <h1>Rezerwacja potwierdzona.</h1>
      </div>
    );
  }

  if (!qrDid) {
    return (
      <div className="confirmation-container2">
        <h1>Wejdź na stronę poprzez kod QR.</h1>
      </div>
    );
  }
  return (
    <div className="confirmation-container2">
      {isDeskAvailable === 2 ? (
        <div>
                    <div className="text-center">

          <h1>
            <span className="confirmation-icon">
              {response.rodzaj === 1 ? (
                <TableRestaurantIcon fontSize="medium" />
              ) : (
                <DirectionsCarIcon />
              )}
            </span>
            {response.nazwa},
            {response.lokalizacja === 1 ? (
              <span>Kraków</span>
            ) : response.lokalizacja === 2 ? (
              <span>Warszawa</span>
            ) : response.lokalizacja === 3 ? (
              <span>Wrocław</span>
            ) : response.lokalizacja === 4 ? (
              <span>Poznań</span>
            ) : (
              ""
            )}
          </h1>
          <h4 className="today">{today}</h4>

          Posiadasz zarezerwowane biurko: {response.nazwa}
          <div>Czy chcesz potwierdzić rezerwacje?</div>
          </div>
          <div className="qrbuttons">
            <button onClick={() => onSubmit(3)}>Tak</button>
            <button onClick={() => onSubmit(2)}>Nie</button>
          </div>
        </div>
      ) : isDeskAvailable === 1 ? (
        <div className="pin-confirm-overlay2">
          <h1>
            <span className="confirmation-icon">
              {response.rodzaj === 1 ? (
                <TableRestaurantIcon fontSize="medium" />
              ) : (
                <DirectionsCarIcon />
              )}
            </span>
            {response.nazwa},
            {response.lokalizacja === 1 ? (
              <span>Kraków</span>
            ) : response.lokalizacja === 2 ? (
              <span>Warszawa</span>
            ) : response.lokalizacja === 3 ? (
              <span>Wrocław</span>
            ) : response.lokalizacja === 4 ? (
              <span>Poznań</span>
            ) : (
              ""
            )}
          </h1>
          <h4 className="today">{today}</h4>

          <div className="nieposiadasz">
            Nie posiadasz rezerwacji na to miejsce, ale nie jest ono
            zarezerwowane przez nikogo innego.
          </div>

          <div className="czy">
            Czy chcesz zarezerwować i potwierdzić rezerwacje biurka?
          </div>
          <div className="qrbuttons">
            <button onClick={() => onSubmit(1)}>Tak</button>
            <button onClick={() => onSubmit(2)}>Nie</button>
          </div>
        </div>
      ):''}
    </div>
  );
};

export default QrConfirmation;

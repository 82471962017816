import React, { useState } from "react";
import "./TopStyle.css";

function Top() {
  return (
    <div className="top">
      <img src="../logo.png" alt="Logo" className="logo" />
      <div className="title-text">
        <h1 className="title">Book a desk</h1>
        <h4 className="title">
          <span>Rezerwacja biurek </span>
          <span> i miejsc parkingowych</span>
        </h4>
      </div>
    </div>
  );
}
export default Top;

import React, { useEffect, useState } from "react";

import "./LoginPage.css";

const LoginPage = ({
  onPinChange,
  onSubmit,
  errorMessage,
  setErrorMessage,
  pinValues,
  setPinValues,
}) => {
  const inputsRef = React.useRef([]);
  const [showError, setShowError] = useState(false);

  const handleInputChange = (e, index) => {
    const value = e.target.value.slice(-1);

    if (!/^[0-9]$/.test(value) && value !== "") {
      return;
    }

    const newPinValues = [...pinValues];
    newPinValues[index] = value;
    setPinValues(newPinValues);
    onPinChange(value, index);

    if (value && index < 3) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (pinValues[index] === "" && index > 0) {
        inputsRef.current[index - 1].focus();
        const newPinValues = [...pinValues];
        newPinValues[index - 1] = "";
        setPinValues(newPinValues);
        onPinChange("", index - 1);
      } else {
        const newPinValues = [...pinValues];
        newPinValues[index] = "";
        setPinValues(newPinValues);
        onPinChange("", index);
      }
    }
    if (e.key === "Enter") {
      if (pinValues.every(val => val !== "")) {
          onSubmit();
      } else {
          setErrorMessage("Wszystkie pola muszą być wypełnione.");
          setShowError(true);
      }
  }
  };
  useEffect(() => {
    if (errorMessage) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!showError) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [showError]);

  useEffect(() => {
    inputsRef.current[0].focus();
  }, []);

  return (
    <div className="container3">
      <div className="textcontainer">
        <h2>Wpisz pin</h2>
      </div>
      <div className="container">
        <div className="error-message-container">
          <span className={`error-message ${showError ? "visible" : ""}`}>
            {errorMessage}
          </span>
        </div>

        <div className="input-container">
          {[...Array(4)].map((_, i) => (
            <input
              key={i}
              className="input-field2"
              type="tel"
              maxLength={1}
              value={pinValues[i]}
              onChange={(e) => handleInputChange(e, i)}
              onKeyDown={(e) => handleKeyDown(e, i)}
              ref={(ref) => (inputsRef.current[i] = ref)}
              style={{ WebkitTextSecurity: "disc" }}
            />
          ))}
        </div>
        <div>
          <input
            className="submit-button"
            type="submit"
            value="ENTER"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

import React from 'react';
import "../Modals/ReservationConfirm.css";


const ConfirmModal = ({ message, onConfirm, onCancel }) => {
    
    return (
        <div className="pin-confirm-overlay">


          <p>{message}</p>
          <button className='confirmbutton' onClick={onConfirm}>Potwierdź</button>
          <button  className='confirmbutton' onClick={onCancel}>Anuluj</button>
        </div>

    );
  };

export default ConfirmModal;

// UserEdit.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import { API_URL } from '../../config';

//material ui
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UserDelete from "./UserDelete";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


import Confirmation from "../Modals/Confirmation";
import "./UserEdit.css";



const ReservationEdit = ({isAdmin}) => {
    const { id } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalMessage2, setModalMessage2] = useState("");
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [modalMessage3, setModalMessage3] = useState("");
    const [isModalVisible3, setIsModalVisible3] = useState(false);
    const [isModalVisible4, setIsModalVisible4] = useState(false);
    const [modalMessage4, setModalMessage4] = useState("");

    const [reservationData, setReservationData] = useState({
        historia_id: '',
        nazwa: '',
        rodzaj: '',
        data_rezerwacji: '',
        uzytkownik: '',
        potwierdzenie: false,

        
    });
    const navigate = useNavigate();



    useEffect(() => {
        const fetchReservationData = async () => {

            try {
                const formData = new FormData();
                formData.append("id", id);
                const response = await axios.post(
                    API_URL + "/getReservation",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (response.data) {
                    setReservationData(response.data);



                }
            } catch (error) {
                console.error("Error during reserved desks fetching:", error);
            }
        };

        fetchReservationData();
    }, [id]);


    if (!isAdmin) {
        return (
          <div className="noPermissions">
            Nie masz uprawnień do przeglądania tej strony.
          </div>
        );
      }

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setReservationData(prevData => ({
            ...prevData,
            [name]: type === "checkbox" ? (checked ? 1 : 0) : value

        }));
    };

    const handleSubmit = async (event) => {
        if (String(reservationData.nazwa).length <2) {
            alert('Nazwa musi składać się z co najmniej 3 znaków.');
            return;
        }

        if (reservationData.uzytkownik=="") {
            alert('Wpisz ID uzytkownika');
            return;
        }
        event.preventDefault();

        try {

            const formData = new FormData();
            for (let key in reservationData) {
                formData.append(key, reservationData[key]);
            }

            const response = await axios.post(
                API_URL + "/updateReservation",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );


            if (response.data && response.data.success) {
                afterEdit();
            } else {
                afterEdit2(response.data.message);
            }
        } catch (error) {
            console.error("Error updating user data:", error);
            alert('Wystąpił błąd podczas aktualizacji danych. Proszę spróbować później.');
        }
    };



    const handleUserDelete = async () => {
        try {
            const formData = new FormData();
            formData.append("id", id);

            const response = await axios.post(API_URL + "/reservationDelete", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data.done) {
            }
        } catch (error) {
            console.error("Error during reservation cancelling:", error);
        }
    };

    const handleReservationDeleteClick = () => {
        setModalMessage("Czy na pewno chcesz usunąć tę rezerwację?");
        setIsModalVisible(true);
        document.body.style.overflow = "hidden";
    };


    const afterDelete = () => {
        setModalMessage2("Usunięto.");
        setIsModalVisible2(true);
        document.body.style.overflow = "hidden";
    };

    const afterEdit = () => {
        setModalMessage3("Zmodyfikowano rekord.");
        setIsModalVisible3(true);
        document.body.style.overflow = "hidden";
    };

    const afterEdit2 = (message) => {
        setModalMessage4(message);
        setIsModalVisible4(true);
        document.body.style.overflow = "hidden";
    };
    return (
        <div className='testt'>
            <div className='backbutton' onClick={() => navigate(`/adminPanel?table=reservations`)}>
                <ArrowBackIcon />
            </div>
            <div className="user-edit-container">
                <h2 className='title'>Edycja rezerwacji ({reservationData.historia_id})             <button className="delete-user" onClick={() => handleReservationDeleteClick()}><DeleteForeverIcon /></button>
                </h2>

                <form onSubmit={handleSubmit}>
                   
                    <div className="input-group">
                        <label>NAZWA</label>
                        <input
                            type="text"
                            name="nazwa"
                            value={reservationData.nazwa}
                            onChange={handleChange}
                            pattern="\d{4}"
                            title="PIN powinien składać się z dokładnie 4 cyfr"
                            required
                            disabled
                        />                </div>
                    <div className="input-group">
                        <label>data_rezerwacji</label>
                        <input type="date" name="data_rezerwacji" value={reservationData.data_rezerwacji} onChange={handleChange} min="0" max="4" required />
</div>

<div className="input-group">

                        <label>ID użytkownika</label>
                            <input type="number" name="uzytkownik" value={reservationData.uzytkownik} onChange={handleChange} />
                        <label className='temp'>
                            <input type="checkbox" name="potwierdzenie" checked={reservationData.potwierdzenie} onChange={handleChange} />
                            Potwierdzenie
                        </label>
                        

                    </div>
                    <button type="submit">Zapisz zmiany</button>
                </form>
            </div>

            {isModalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <UserDelete
                            message={modalMessage}
                            onConfirm={() => {
                                handleUserDelete(id);
                                setIsModalVisible(false);
                                document.body.style.overflow = "auto";
                                afterDelete("Usunięto.");

                                // navigate(`/adminPanel?table=users`)

                            }}
                            onCancel={() => {
                                document.body.style.overflow = "auto";
                                setIsModalVisible(false)
                            }}
                        />
                    </div>
                </div>
            )}


            {isModalVisible2 && (
                <div className="modal">
                    <div className="modal-content">
                        <Confirmation
                            message={modalMessage2}
                            onConfirm={() => {
                                setIsModalVisible2(false);
                                document.body.style.overflow = "auto";



                            }}
                            onClose={() => {
                                setIsModalVisible2(false);
                                document.body.style.overflow = "auto";
                                navigate(`/adminPanel?table=reservations`);
                            }}
                        />
                    </div>
                </div>
            )}


            {isModalVisible3 && (
                <div className="modal">
                    <div className="modal-content">
                        <Confirmation
                            message={modalMessage3}
                            onConfirm={() => {
                                setIsModalVisible3(false);
                                document.body.style.overflow = "auto";
                                //afterDelete("Zmodyfikowano rekord.");



                            }}
                            onClose={() => {
                                setIsModalVisible3(false);
                                document.body.style.overflow = "auto";
                                navigate(`/adminPanel?table=reservations`);
                            }}
                        />
                    </div>
                </div>
            )}



            {isModalVisible4 && (
                <div className="modal">
                    <div className="modal-content">
                        <Confirmation
                            message={modalMessage4}
                            onConfirm={() => {
                                setIsModalVisible4(false);
                                document.body.style.overflow = "auto";



                            }}
                            onClose={() => {
                                setIsModalVisible4(false);
                                document.body.style.overflow = "auto";
                                //navigate(`/adminPanel?table=users`)  ;                  

                            }}
                        />
                    </div>
                </div>
            )}
        </div>




    );
};

export default ReservationEdit;

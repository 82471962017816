import React, { useEffect, useState, useCallback } from "react";
import "./HomePage.css";
import axios from "axios";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import CheckIcon from "@mui/icons-material/Check";
import ReservationConfirm from "../../components/Modals/ReservationConfirm";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import CancelModal from "../../components/Modals/CancelModal";
import DeleteConfirmModal from "../../components/Modals/DeleteConfirmModal";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Confirmation from "../../components/Modals/Confirmation";

import { API_URL } from "../../config";
import { useNavigate } from "react-router-dom";

const HomePage = ({
  userId,
  setUserId,
  onLogout,
  pinValues,
  setPinValues,
  isAdmin,
  setIsAdmin,
  ifDeskReserved,
  setIfDeskReserved,
  reservedDeskModal,
  setReservedDeskModal,
  reservedDeskModalMessage,
  setReservedDeskModalMessage
}) => {
  const [desks, setDesks] = useState([]);
  const [isBlocked, setIsBlocked] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [forSomeone, setForSomeone] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const [forUser, setForUser] = useState(null);
  const [forUserId, setForUserId] = useState(null);
  const [transformedData, setTransformedData] = useState([]);


  const [currentDeskType, setCurrentDeskType] = useState(null);

  const [modalMessage, setModalMessage] = useState("");

  const [testDate, setTestDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [reservedDesks, setReservedDesks] = useState({ 1: null, 2: null });

  const [selectedDesks, setSelectedDesks] = useState({
    1: null, // Dla biurek
    2: null, // Dla garaży
  });
  const [selectedDesk, setSelectedDesk] = useState(null);

  const handleReserveClick = (deskId, deskType) => {
    const desk = desks.find((d) => d.did === deskId && d.rodzaj === deskType);
    setSelectedDesk(desk);
    document.body.style.overflow = "hidden";
  };

  const handleCancelClick = (userId, deskId, deskType) => {
    setCurrentDeskType(deskType); // dodaj to
    setModalMessage("Czy na pewno chcesz anulować rezerwację?");
    setIsModalVisible2(true);
    document.body.style.overflow = "hidden";
  };

  const handleConfirmClick = (userId, deskId, deskType) => {
    setCurrentDeskType(deskType); // dodaj to
    setModalMessage("Czy na pewno chcesz potwierdzić rezerwację?");
    setIsModalVisible(true);

    document.body.style.overflow = "hidden";
  };
  const handleDeleteConfirmClick = (userId, deskId, deskType) => {
    setCurrentDeskType(deskType); // dodaj to
    setModalMessage("Czy na pewno chcesz usunąć potwierdzenie rezerwacji?");
    setIsModalVisible3(true);
    document.body.style.overflow = "hidden";
  };
  const handleClose = () => {
    setSelectedDesk(null);
    document.body.style.overflow = "auto";
  };
  const handleLogout = () => {
    onLogout();
    setPinValues(Array(4).fill(""));
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userId");
    localStorage.removeItem("pin");
  };
  const navigate = useNavigate(); // Zwraca funkcję do nawigacji

  const handleAdminPanel = () => {
    //console.log("Powinno przekierowac");
    navigate("/adminPanel?table=summary");
  };

  const fetchDesks = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append("date", testDate);
      formData.append("uid", userId);

      const response = await axios.post(API_URL + "/getDesks", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data["blocked"]) {
        setIsBlocked(true);
        console.log("BANNED");
      } else {
        if (response.data) {
          //console.log(userId);
          //console.log("GGGGGGGGG", response.data);
          setDesks(response.data);
        }
      }
      //console.log(response.data); // dodaj tutaj
    } catch (error) {
      console.error("Error during desks fetching:", error);
    }
  }, [testDate, userId]); // Dodaj tutaj zależności używane wewnątrz fetchDesks

  const fetchDesks2 = useCallback(
    async (forUserId) => {
      try {
        const formData = new FormData();
        formData.append("date", testDate);
        formData.append("uid", forUserId);

        const response = await axios.post(API_URL + "/getDesks", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data["blocked"]) {
          setIsBlocked(true);
          console.log("BANNED");
        } else {
          if (response.data) {
            //console.log("SSSSSSSSS",forUserId);

            //console.log("JJJJJJ",response.data)
            setDesks(response.data);
          }
        }
        //console.log(response.data); // dodaj tutaj
      } catch (error) {
        console.error("Error during desks fetching:", error);
      }
    },
    [testDate, forUserId]
  ); // Dodaj tutaj zależności używane wewnątrz fetchDesks

  const fetchReservedDesks = useCallback(
    async (userId, date) => {
      try {
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("date", date);
        const response = await axios.post(
          API_URL + "/getReservedDesks",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data) {
          //console.log("ZZZZZZZZZZZZ", userId);
          //console.log("ZZZZZZZZZZZZ", response.data);

          const transformedData = response.data.reduce((acc, curr) => {
            acc[curr.rodzaj] = curr;
            return acc;
          }, {});

          setReservedDesks(transformedData);
        }
      } catch (error) {
        console.error("Error during reserved desks fetching:", error);
      }
    },
    []
  ); // Dodaj tutaj zależności używane wewnątrz fetchDesks

  const fetchReservedDesks2 = useCallback(
    async (forUserId, date) => {
      try {
        const formData = new FormData();
        formData.append("userId", forUserId);
        formData.append("date", date);
        const response = await axios.post(
          API_URL + "/getReservedDesks",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data) {
          //console.log("ZZZZZZZZZZZZ", forUserId);
          //console.log("ZZZZZZZZZZZZ", response.data);

          const transformedData = response.data.reduce((acc, curr) => {
            acc[curr.rodzaj] = curr;
            return acc;
          }, {});

          setReservedDesks(transformedData);
        }
      } catch (error) {
        console.error("Error during reserved desks fetching:", error);
      }
    },
    [reservedDesks]
  ); // Dodaj tutaj zależności używane wewnątrz fetchDesks


  const handleDeskClick = (deskId, deskType) => {
    if (!reservedDesks[deskType]) {
      setSelectedDesks((prevState) => ({
        ...prevState,
        [deskType]: prevState[deskType] === deskId ? null : deskId,
      }));
    }
  };

  const getDeskName = (desk) => {
    if (desk.lokalizacja === 1) {
      return "Kraków";
    } else if (desk.lokalizacja === 2) {
      return "Warszawa";
    } else if (desk.lokalizacja === 3) {
      return "Wrocław";
    } else if (desk.lokalizacja === 4) {
      return "Poznań";
    } else {
      return desk.lokalizacja;
    }
  };

  const handleCancelReservation = async (userId, deskId, deskType) => {
    try {
      const formData = new FormData();
      formData.append("did", deskId);
      formData.append("date", testDate); // Dodajemy datę do formularza

      const response = await axios.post(
        API_URL + "/cancelReservation",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.done) {
        if(forSomeone)
        {
         // console.log(forUser)
          //console.log(forUserId)

          fetchReservedDesks(forUserId, testDate);

        }else
        {
          fetchReservedDesks(userId, testDate);

        }
        fetchDesks();
      }
    } catch (error) {
      console.error("Error during reservation cancelling:", error);
    }
  };

  const handleConfirmReservation = async (userId, deskId, deskType) => {
    try {
      const formData = new FormData();
      formData.append("did", deskId);
      formData.append("date", testDate); // Dodajemy datę do formularza

      const response = await axios.post(
        API_URL + "/confirmReservation",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.done) {
        if(forSomeone)
        {
          //console.log(forUser)
          //console.log(forUserId)

          fetchReservedDesks(forUserId, testDate);

        }else
        {
          fetchReservedDesks(userId, testDate);

        }
        fetchDesks();
      }
    } catch (error) {
      console.error("Error during reservation cancelling:", error);
    }
  };

  const handleDeleteConfirmReservation = async (userId, deskId, deskType) => {
    try {
      const formData = new FormData();
      formData.append("did", deskId);
      formData.append("date", testDate); // Dodajemy datę do formularza

      const response = await axios.post(
        API_URL + "/deleteConfirmReservation",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.done) {
        if(forSomeone)
        {
          //console.log(forUser)
          //console.log(forUserId)

          fetchReservedDesks(forUserId, testDate);

        }else
        {
          fetchReservedDesks(userId, testDate);

        }        fetchDesks();
      }
    } catch (error) {
      console.error("Error during reservation cancelling:", error);
    }
  };

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  let futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 7);
  let future_dd = String(futureDate.getDate()).padStart(2, "0");
  let future_mm = String(futureDate.getMonth() + 1).padStart(2, "0"); //Styczeń to 0!
  let future_yyyy = futureDate.getFullYear();
  futureDate = future_yyyy + "-" + future_mm + "-" + future_dd;

  const transformData = (data) => {
    return data.map((item) => ({
      value: item.id,
      uid: item.uid,
      label: `${item.id}(${item.pin})`,
    }));
  };

const reserveForSomeone = async (userId) => {
try {
const formData = new FormData();
formData.append("userId", userId);
formData.append("date", testDate);
const response = await axios.post(
API_URL + "/reserveForSomeone",
formData,
{
headers: {
"Content-Type": "multipart/form-data",
},
}
);
if (response.data) {
const transformed = transformData(response.data);
setUsersData(transformed);
setForUser(response.data[0].id);
}
} catch (error) {
console.error("Error during reservation cancelling:", error);
}
};


  //23.08.2023
  //}, [forSomeone, transformedData, userId, testDate]);

  // ... (reszta kodu)
  ///////////////////



////////USE.EFFECTS

useEffect(() => {
  if (forSomeone) {
    async function fetchAvailableUsers() {
      try {
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("date", testDate);

        const response = await axios.post(
          API_URL + "/reserveForSomeone",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data) {
          const transformed = transformData(response.data);
          setUsersData(transformed);
          //console.log(transformed)
          setForUser(transformed[0].value)
          setForUserId(transformed[0].uid)
        }
      } catch (error) {
        console.error("Error fetching available users:", error);
      }
    }

    fetchAvailableUsers();
  }
}, [forSomeone, testDate]);


useEffect(() => {
  if (reservedDeskModal) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
}, [reservedDeskModal]);




useEffect(() => {
  if (transformedData && transformedData.length > 0) {
    if (!forSomeone) {
      //console.log("forSomeone=0");
      reserveForSomeone(userId);
      fetchDesks(userId);
      fetchReservedDesks(userId, testDate);
    } else {
     // console.log("forSomeone=1");
      fetchDesks2(transformedData[0].uid);
      fetchReservedDesks(userId, testDate);
    }
   // console.log("UPDATE");
  }
}, [forSomeone]);




useEffect(() => {
  if (testDate) {
    let currentUserId = userId;
    if (!currentUserId) {
      currentUserId = document.getElementById("uid").value;
      setUserId(currentUserId);
    }

    fetchDesks();

    if (currentUserId) {
      if (!forSomeone) {
        fetchReservedDesks(currentUserId, testDate);
      } else {
        fetchReservedDesks2(forUserId, testDate);
      }
    }
  }
}, [testDate, userId, forSomeone, forUserId]);




/////////////////











  return (
    <div className="HomePageContainer">
      {isAdmin === 1 ? (
        <button className="adminpanel-button" onClick={handleAdminPanel}>
          Admin Panel
        </button>
      ) : (
        ""
      )}

      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>

      <input type="hidden" id="uid" value={userId} />



      {isAdmin ? (
            <div className="forSomeone">
              <span
                className={`reserve-for ${
                  forSomeone ? "reserve-for-active" : ""
                }`}
                onClick={() => setForSomeone((prev) => !prev)}
              >
                Zarezerwuj dla kogoś (Admin)
                <PersonAddAltIcon />
              </span>
              {forSomeone ? (
                <select
                  onChange={(e) => {
                    const selectedData = JSON.parse(e.target.value); // Parse the value which we stored as JSON
                    setForUser(selectedData.value);
                    setForUserId(selectedData.uid);
                    fetchDesks2(selectedData.uid);
                    fetchReservedDesks(selectedData.uid, testDate);
                  }}
                >
                  {usersData.map((item) => (
                    <option
                      key={item.value}
                      value={JSON.stringify({
                        value: item.value,
                        uid: item.uid,
                      })}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}


<label className="pasek">
            <span className="choose-date">Wybierz datę:</span>
            <input
              className="date-input"
              type="date"
              min={today}
              max={futureDate}
              value={testDate}
              onChange={(e) => {
                setTestDate(e.target.value);
              }}
            />
          </label>


      {(reservedDesks[1] || reservedDesks[2]) && (
        <div className="reserved-info">
          {reservedDesks[1] && (
            <div className="info-item">
              <div className="geet3"></div>

              <div
                className={`zarezerwowane-miejsce ${
                  reservedDesks[1].potwierdzenie === 1
                    ? "reserved-confirmed"
                    : ""
                }`}
              >
                <div
                  className={`info-title ${
                    reservedDesks[1].potwierdzenie === 1
                      ? "reserved-confirmed"
                      : ""
                  }`}
                >
                  Zarezerwowane biurko:
                </div>
                <div
                  className={`info-description ${
                    reservedDesks[1].potwierdzenie === 1
                      ? "reserved-confirmed"
                      : ""
                  }`}
                >
                  <div
                    className={`${
                      reservedDesks[1].potwierdzenie === 1
                        ? "reserved-confirmed"
                        : ""
                    }`}
                  >
                    {reservedDesks[1].nazwa}, {getDeskName(reservedDesks[1])}
                  </div>
                  <div
                    className={`smallletters ${
                      reservedDesks[1].potwierdzenie === 1
                        ? "reserved-confirmed"
                        : ""
                    }`}
                  >
                  </div>
                </div>
              </div>

              <div className="margin-auto">
                <button
                  className="cancel-reservation"
                  onClick={() =>
                    handleCancelClick(userId, reservedDesks[1].did, 1)
                  }
                >
                  <DeleteForeverIcon />
                </button>

                {reservedDesks[1].potwierdzenie === 0 ? (
                  testDate === today ? (
                    <button
                      className="confirm-reservation"
                      onClick={() =>
                        handleConfirmClick(userId, reservedDesks[1].did, 1)
                      }
                    >
                      <CheckIcon />
                    </button>
                  ) : (
                    ""
                  )
                ) : (
                  <button
                    className="confirm-reservation"
                    onClick={() =>
                      handleDeleteConfirmClick(userId, reservedDesks[1].did, 1)
                    }
                  >
                    <DoDisturbAltIcon />
                  </button>
                )}
              </div>
            </div>
          )}

          {reservedDesks[2] && (
            <div className="info-item">
              <div className="geet4"></div>
              <div
                className={`zarezerwowane-miejsce ${
                  reservedDesks[2] && reservedDesks[2].potwierdzenie === 1
                    ? "reserved-confirmed"
                    : ""
                }`}
              >
                <div
                  className={`info-title ${
                    reservedDesks[2] && reservedDesks[2].potwierdzenie === 1
                      ? "reserved-confirmed"
                      : ""
                  }`}
                >
                  Zarezerwowany garaż:
                </div>
                <div
                  className={`info-description ${
                    reservedDesks[2] && reservedDesks[2].potwierdzenie === 1
                      ? "reserved-confirmed"
                      : ""
                  }`}
                >
                  <div
                    className={`${
                      reservedDesks[2].potwierdzenie === 1
                        ? "reserved-confirmed"
                        : ""
                    }`}
                  >
                    {reservedDesks[2].nazwa}, {getDeskName(reservedDesks[2])}
                  </div>
                  <div
                    className={`smallletters ${
                      reservedDesks[2] && reservedDesks[2].potwierdzenie === 1
                        ? "reserved-confirmed"
                        : ""
                    }`}
                  >
                  </div>
                </div>
              </div>

              <div className="margin-auto">
                <button
                  className="cancel-reservation"
                  onClick={() =>
                    handleCancelClick(userId, reservedDesks[2].did, 2)
                  }
                >
                  <DeleteForeverIcon />
                </button>

                {reservedDesks[2].potwierdzenie === 0 ? (
                  testDate === today ? (
                    <button
                      className="confirm-reservation"
                      onClick={() =>
                        handleConfirmClick(userId, reservedDesks[2].did, 2)
                      }
                    >
                      <CheckIcon />
                    </button>
                  ) : (
                    ""
                  )
                ) : (
                  <button
                    className="confirm-reservation"
                    onClick={() =>
                      handleDeleteConfirmClick(userId, reservedDesks[2].did, 2)
                    }
                  >
                    <DoDisturbAltIcon />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {isBlocked ? (
        <div className="blocked">
          <div>Twoje konto zostało zablokowane.</div>
          <div>Skontaktuj się z administratorem systemu.</div>
        </div>
      ) : (
        <>



          {desks.map((desk, index) => (
            <div className="desk-container">
              <div
                key={desk.did}
                className={`desk ${
                  desk.did === selectedDesks[desk.rodzaj] ? "selected" : ""
                } ${reservedDesks[desk.rodzaj] ? "reserved" : ""} 
                ${
                  desk.did === ifDeskReserved ? "green" : ""
                }
                `}
                onClick={() => handleDeskClick(desk.did, desk.rodzaj)}
              >
                <div className="desk-name">{getDeskName(desk)}</div>
                <div className="desk-number">{desk.nazwa}</div>
                {desk.did === ifDeskReserved ?(
<div className="smallletters">
                <div className="desk-did">Próbowano zarezerwować z kodu QR</div>
                </div>
                ):''}
                {/*<div className="desk-did">{desk.did}</div>*/}
                <div className="desk-icon">
                  {desk.rodzaj === 1 ? (
<div className="geet"></div>                 ) : (
                    <div className="geet2"></div>
                  )}
                </div>
              </div>
              <button
                className={`reservation-btn ${
                  desk.did === selectedDesks[desk.rodzaj] ? "visible" : ""
                }`}
                onClick={() => handleReserveClick(desk.did, desk.rodzaj)}
                disabled={!!reservedDesks[desk.rodzaj]}
              >
                Rezerwuj
              </button>
            </div>
          ))}

          {selectedDesk && (
            <div className="modal">
              <div className="modal-content">
                <ReservationConfirm
                  desk={selectedDesk}
                  onCancel={handleClose}
                  testDate={testDate}
                  refreshDesks={fetchDesks}
                  refreshReservations={() =>
                    fetchReservedDesks(userId, testDate)
                  } // Aktualizuj funkcję

                  refreshReservations2={() =>
                    fetchReservedDesks(forUserId, testDate)
                  } // Aktualizuj funkcję
                  resetSelectedDesks={() =>
                    setSelectedDesks({ 1: null, 2: null })
                  }
                  selectedDesk={selectedDesk}
                  isBlocked={isBlocked}
                  setIsBlocked={setIsBlocked}
                  forSomeone={forSomeone}
                  forUser={forUser}
                />
              </div>
            </div>
          )}
          {isModalVisible && (
            <div className="modal">
              <div className="modal-content">
                <ConfirmModal
                  message={modalMessage}
                  onConfirm={() => {
                    handleConfirmReservation(
                      userId,
                      reservedDesks[currentDeskType].did,
                      currentDeskType
                    );
                    setIsModalVisible(false);
                    document.body.style.overflow = "auto";
                  }}
                  onCancel={() => {
                    document.body.style.overflow = "auto";
                    setIsModalVisible(false);
                  }}
                />
              </div>
            </div>
          )}

          {isModalVisible2 && (
            <div className="modal">
              <div className="modal-content">
                <CancelModal
                  message={modalMessage}
                  onConfirm={() => {
                    handleCancelReservation(
                      userId,
                      reservedDesks[currentDeskType].did,
                      currentDeskType
                    );
                    setIsModalVisible2(false);
                    document.body.style.overflow = "auto";
                  }}
                  onCancel={() => {
                    document.body.style.overflow = "auto";
                    setIsModalVisible2(false);
                  }}
                />
              </div>
            </div>
          )}

          {isModalVisible3 && (
            <div className="modal">
              <div className="modal-content">
                <DeleteConfirmModal
                  message={modalMessage}
                  onConfirm={() => {
                    handleDeleteConfirmReservation(
                      userId,
                      reservedDesks[currentDeskType].did,
                      currentDeskType
                    );
                    setIsModalVisible3(false);
                    document.body.style.overflow = "auto";
                  }}
                  onCancel={() => {
                    document.body.style.overflow = "auto";
                    setIsModalVisible3(false);
                  }}
                />
              </div>
            </div>
          )}



{reservedDeskModal && (
            
            <div className="modal">
              <div className="modal-content">
                <Confirmation
                  message={reservedDeskModalMessage}
                  

                  onClose={() => {
                    document.body.style.overflow = "auto";
                    setReservedDeskModal(false);
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HomePage;

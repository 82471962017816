import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import { API_URL } from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function QrTest({ userId, isAdmin, handleLogout }) {
  const url = API_URL + "/qr/4d931cc2-8853-4936-ba7b-54f8602ff7c9"; // Twój link
  const [desks, setDesks] = useState([]);
  const navigate = useNavigate(); // Zwraca funkcję do nawigacji

  const getQrDids = async () => {
    try {
      const formData = new FormData();
      formData.append("userId", userId);
      const response = await axios.post(API_URL + "/getDesksAdmin", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data && Array.isArray(response.data)) {
        setDesks(response.data);
      }

      return {};
    } catch (error) {
      console.error("Error during PIN verification:", error);
    }
  };

  function getCityName(lokalizacja) {
    switch (lokalizacja) {
      case 1:
        return "Kraków";
      case 2:
        return "Warszawa";
      case 3:
        return "Wrocław";
      case 4:
        return "Poznań";
      default:
        return "Nieznana lokalizacja";
    }
  }

  useEffect(() => {
    getQrDids();
  }, []);

  return (
    <div>

{isAdmin === 1 ? (
  <button className="adminpanel-button" onClick={() => navigate("/adminpanel?table=summary")}>
    Admin Panel
</button>


      ) : (
        ""
      )}

      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>

      
      {desks.map((desk) => (
        <div key={desk.id} style={{ margin: "100px", textAlign: "center" }}>
          <div>
            {" "}
            {desk.nazwa}, {getCityName(desk.lokalizacja)}
          </div>

          <QRCode value={`${API_URL}/qr/${desk.did}`} />
        </div>
      ))}
    </div>
  );
}

export default QrTest;

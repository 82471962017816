// UserEdit.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';
import "./UserEdit.css";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UserDelete from "./UserDelete";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Confirmation from "../Modals/Confirmation";



const UserEdit = ({ isAdmin, adminId }) => {
    const { userId } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalMessage2, setModalMessage2] = useState("");
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [modalMessage3, setModalMessage3] = useState("");
    const [isModalVisible3, setIsModalVisible3] = useState(false);
    const [isModalVisible4, setIsModalVisible4] = useState(false);
    const [modalMessage4, setModalMessage4] = useState("");
    const [adminPermissions, setAdminPermissions] = useState("");


    const [userData, setUserData] = useState({
        uid: '',
        pin: '',
        aktywny: false,
        lokalizacja1: false,
        lokalizacja2: false,
        lokalizacja3: false,
        lokalizacja4: false,
        garaz: false,
        weekend: false,
        biurko: false,
        isAdmin: false

        
    });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {

            try {
                const formData = new FormData();
                formData.append("userId", userId);
                formData.append("adminId", adminId);


                const response = await axios.post(
                    API_URL + "/getUser",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (response.data) {
                    const user = response.data;
                    setAdminPermissions(user.adminPermissions);
                    setUserData(user);


                }
            } catch (error) {
                console.error("Error during reserved desks fetching:", error);
            }
        };

        fetchUserData();
    }, [userId]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: type === "checkbox" ? (checked ? 1 : 0) : value

        }));
    };

    const handleSubmit = async (event) => {
        if (String(userData.pin).length !== 4) {
            alert('PIN musi składać się z 4 znaków.');
            return;
        }

        if (userData.aktywny < 0 || userData.aktywny > 4) {
            alert('Pole "Aktywny" może przyjmować jedynie wartości od 0 do 4');
            return;
        }
        event.preventDefault();

        try {
            const formData = new FormData();
            for (let key in userData) {
                formData.append(key, userData[key]);
            }

            const response = await axios.post(
                API_URL + "/updateUser",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );


            if (response.data && response.data.success) {
                //alert(response.data.message);
                afterEdit();
                //navigate(`/adminPanel?table=users`);

            } else {
                afterEdit2(response.data.message);
            }
        } catch (error) {
            console.error("Error updating user data:", error);
            alert('Wystąpił błąd podczas aktualizacji danych. Proszę spróbować później.');
        }
    };



    const handleUserDelete = async () => {
        try {
            const formData = new FormData();
            formData.append("uid", userId);

            const response = await axios.post(API_URL + "/userDelete", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data.done) {
                //afterDelete(response.data.message);

            }
        } catch (error) {
            console.error("Error during reservation cancelling:", error);
        }
    };

    const handleUserDeleteClick = () => {
        setModalMessage("Czy na pewno chcesz usunąć tego użytkownika?");
        setIsModalVisible(true);
        document.body.style.overflow = "hidden";
    };


    const afterDelete = () => {
        setModalMessage2("Usunięto.");
        setIsModalVisible2(true);
        document.body.style.overflow = "hidden";
    };

    const afterEdit = () => {
        setModalMessage3("Zmodyfikowano rekord.");
        setIsModalVisible3(true);
        document.body.style.overflow = "hidden";
    };

    const afterEdit2 = (message) => {
        setModalMessage4(message);
        setIsModalVisible4(true);
        document.body.style.overflow = "hidden";
    };

    if (!isAdmin) {
        return <div className='noPermissions'>Nie masz uprawnień do przeglądania tej strony.</div>;
    }
    return (
        <div className='testt'>
            <div className='backbutton' onClick={() => navigate(`/adminPanel?table=users`)}>
                <ArrowBackIcon />
            </div>
            <div className="user-edit-container">
                <h2 className='title'>Edycja użytkownika ({userData.id})             <button className="delete-user" onClick={() => handleUserDeleteClick()}><DeleteForeverIcon /></button>
                </h2>

                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>UID</label>
                        <input type="text" name="uid" value={userData.uid} onChange={handleChange} disabled />
                    </div>
                    <div className="input-group">
                        <label>PIN</label>
                        <input
                            type="text"
                            name="pin"
                            value={userData.pin}
                            onChange={handleChange}
                            pattern="\d{4}"
                            title="PIN powinien składać się z dokładnie 4 cyfr"
                            required
                        />                </div>
                    <div className="input-group">
                        <label>Aktywny</label>
                        <input type="number" name="aktywny" value={userData.aktywny} onChange={handleChange} min="0" max="4" required />

    {adminPermissions.lokalizacja1 ?( 
        <span>
                                    <label>

    <input type="checkbox" name="lokalizacja1" checked={userData.lokalizacja1} onChange={handleChange} />
    Kraków
</label>
</span>
):''}
    {adminPermissions.lokalizacja2 ?(
    <span>
        <label>
    <input type="checkbox" name="lokalizacja2" checked={userData.lokalizacja2} onChange={handleChange} />
    Warszawa
</label>
</span>
    ):''}
    {adminPermissions.lokalizacja3 ?(
        <span>
            <label>

    <input type="checkbox" name="lokalizacja3" checked={userData.lokalizacja3} onChange={handleChange} />
    Wrocław
</label>
</span>
    ):''}
    {adminPermissions.lokalizacja4 ?(
    <span>
        <label>

    <input type="checkbox" name="lokalizacja4" checked={userData.lokalizacja4} onChange={handleChange} />
    Poznań
</label>
</span>
    ):''}

{adminPermissions.biurko ?(
<span>
                        <label>
                            <input type="checkbox" name="biurko" checked={userData.biurko} onChange={handleChange} />
                            Biurko
                        </label>
                        </span>
):''}


{adminPermissions.garaz ?(

                        <label>
                            <input type="checkbox" name="garaz" checked={userData.garaz} onChange={handleChange} />
                            Garaż
                        </label>

):''}

{adminPermissions.weekend ?(

                        <label>
                            <input type="checkbox" name="weekend" checked={userData.weekend} onChange={handleChange} />
                            Weekend
                        </label>
):''}

                    </div>
                    <button type="submit">Zapisz zmiany</button>
                </form>
            </div>

            {isModalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <UserDelete
                            message={modalMessage}
                            onConfirm={() => {
                                handleUserDelete(userId);
                                setIsModalVisible(false);
                                document.body.style.overflow = "auto";
                                afterDelete("Usunięto.");

                                // navigate(`/adminPanel?table=users`)

                            }}
                            onCancel={() => {
                                document.body.style.overflow = "auto";
                                setIsModalVisible(false)
                            }}
                        />
                    </div>
                </div>
            )}


            {isModalVisible2 && (
                <div className="modal">
                    <div className="modal-content">
                        <Confirmation
                            message={modalMessage2}
                            onConfirm={() => {
                                setIsModalVisible2(false);
                                document.body.style.overflow = "auto";



                            }}
                            onClose={() => {
                                setIsModalVisible2(false);
                                document.body.style.overflow = "auto";
                                navigate(`/adminPanel?table=users`);
                            }}
                        />
                    </div>
                </div>
            )}


            {isModalVisible3 && (
                <div className="modal">
                    <div className="modal-content">
                        <Confirmation
                            message={modalMessage3}
                            onConfirm={() => {
                                setIsModalVisible3(false);
                                document.body.style.overflow = "auto";
                                //afterDelete("Zmodyfikowano rekord.");



                            }}
                            onClose={() => {
                                setIsModalVisible3(false);
                                document.body.style.overflow = "auto";
                                navigate(`/adminPanel?table=users`);
                            }}
                        />
                    </div>
                </div>
            )}



            {isModalVisible4 && (
                <div className="modal">
                    <div className="modal-content">
                        <Confirmation
                            message={modalMessage4}
                            onConfirm={() => {
                                setIsModalVisible4(false);
                                document.body.style.overflow = "auto";



                            }}
                            onClose={() => {
                                setIsModalVisible4(false);
                                document.body.style.overflow = "auto";
                                //navigate(`/adminPanel?table=users`)  ;                  

                            }}
                        />
                    </div>
                </div>
            )}
        </div>




    );
};

export default UserEdit;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";

import "../Modals/ReservationConfirm";

import { API_URL } from "../../config";

const QrLogin = ({ setQrDid }) => {
  const navigate = useNavigate();
  const { did } = useParams();

  const inputsRef = React.useRef([]);
  const [showError, setShowError] = useState(false);
  const [pin, setPin] = useState(Array(4).fill("")); // Inicjalizuj stan jako tablicę pustych stringów
  const [userId, setUserId] = useState(localStorage.getItem("userId") || "-");
  const [errorMessage, setErrorMessage] = useState("");
  const [isAdmin, setIsAdmin] = useState("");
  const [pinValues, setPinValues] = useState(Array(4).fill(""));
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  const verifyPin = async (pin) => {
    try {
      const formData = new FormData();
      formData.append("pin", pin);
      const response = await axios.post(API_URL + "/verifyPin", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        isPinValid: response.data.isPinValid,
        uid: response.data.uid,
        isAdmin: response.data.isAdmin,
        lokalizacja1: response.data.lokalizacja1,
        lokalizacja2: response.data.lokalizacja2,
        lokalizacja3: response.data.lokalizacja3,
        lokalizacja4: response.data.lokalizacja4,
      };
    } catch (error) {
      console.error("Error during PIN verification:", error);
      return {
        isPinValid: false,
        uid: null,
        isAdmin: null,
        lokalizacja1: null,
        lokalizacja1: null,
        lokalizacja1: null,
        lokalizacja1: null,
      };
    }
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value.slice(-1);

    if (!/^[0-9]$/.test(value) && value !== "") {
      return;
    }

    const newPinValues = [...pinValues];
    newPinValues[index] = value;
    setPinValues(newPinValues);
    onPinChange(value, index);

    if (value && index < 3) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (pinValues[index] === "" && index > 0) {
        inputsRef.current[index - 1].focus();
        const newPinValues = [...pinValues];
        newPinValues[index - 1] = "";
        setPinValues(newPinValues);
        onPinChange("", index - 1);
      } else {
        const newPinValues = [...pinValues];
        newPinValues[index] = "";
        setPinValues(newPinValues);
        onPinChange("", index);
      }
    }
    if (e.key === "Enter") {
      if (pinValues.every(val => val !== "")) {
          onSubmit();
      } else {
          setErrorMessage("Wszystkie pola muszą być wypełnione.");
          setShowError(true);
      }
  }
  };
  useEffect(() => {
    if (errorMessage) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!showError) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [showError]);

  useEffect(() => {
    inputsRef.current[0].focus();
  }, []);

  const onPinChange = (value, index) => {
    setPin((prevPin) => {
      const newPin = [...prevPin];
      newPin[index] = value;
      return newPin;
    });
  };

  const onSubmit = async () => {
    try {
      const pinString = pin.join("");

      if (pinString.length < 4) {
        setErrorMessage("Proszę wypełnić wszystkie pola PIN.");
        return;
      }
      const pinInt = parseInt(pinString, 10);

      const {
        isPinValid,
        uid,
        isAdmin,
        lokalizacja1,
        lokalizacja2,
        lokalizacja3,
        lokalizacja4,
      } = await verifyPin(pinInt);
      if (isPinValid) {
        setIsLoggedIn(true);
        setUserId(uid);
        setIsAdmin(isAdmin);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("userId", uid);
        localStorage.setItem("pin", pinInt);
        localStorage.setItem("lokalizacja1", lokalizacja1);
        localStorage.setItem("lokalizacja2", lokalizacja2);
        localStorage.setItem("lokalizacja3", lokalizacja3);
        localStorage.setItem("lokalizacja4", lokalizacja4);
        setQrDid(did);
        navigate("/qrconfirmation");
      } else {
        setErrorMessage("PIN jest niepoprawny.");
        setPinValues(Array(4).fill(""));
      }
    } catch (error) {
      console.error("Error during PIN verification:", error);
    }
  };

  return (
    <div className="container3">
      <div className="textcontainer">
        <h4>Wpisz pin</h4>
      </div>
      <div className="container">
        <div className="error-message-container">
          <span className={`error-message ${showError ? "visible" : ""}`}>
            {errorMessage}
          </span>
        </div>

        <div className="input-container">
          {[...Array(4)].map((_, i) => (
            <input
              key={i}
              className="input-field2"
              type="tel"
              maxLength={1}
              value={pinValues[i]}
              onChange={(e) => handleInputChange(e, i)}
              onKeyDown={(e) => handleKeyDown(e, i)}
              ref={(ref) => (inputsRef.current[i] = ref)}
              style={{ WebkitTextSecurity: "disc" }}
            />
          ))}
        </div>
        <div>
          <input
            className="submit-button"
            type="submit"
            value="ENTER"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};
export default QrLogin;

// UserEdit.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import { API_URL } from '../../config';

//material ui
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Confirmation from "../Modals/Confirmation";
import "./UserEdit.css";



const DeskAdd = (isAdmin, setIsAdmin) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const [userData, setUserData] = useState({
        did: uuidv4(),
        id: '',
        lokalizacja: 1,
        nazwa: '',
        rodzaj: 1,

    });

    const accessibleLocations = Array.from({ length: 4 }, (_, i) => i + 1)
   .filter(locationNumber => localStorage.getItem(`lokalizacja${locationNumber}`) === '1');

   //console.log(accessibleLocations)



    const navigate = useNavigate();



    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: type === "checkbox" ? (checked ? 1 : 0) : value
        }));
    };
    

    const handleSubmit = async (event) => {


        event.preventDefault();

        try {
            const formData = new FormData();
            for (let key in userData) {
                formData.append(key, userData[key]);
            }

            const response = await axios.post(
                API_URL + "/deskAdd",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );


            if (response.data && response.data.success) {
                afterAdd(response.data.message);
            } else {

                afterAdd(response.data.message);
            }
        } catch (error) {
            console.error("Error updating user data:", error);
            alert('Wystąpił błąd podczas aktualizacji danych. Proszę spróbować później.');
        }
    };

    const afterAdd = (message) => {
        setModalMessage(message);
        setIsModalVisible(true);
        document.body.style.overflow = "hidden";
    };

    if (!isAdmin['isAdmin']) {
        return <div className='noPermissions'>Nie masz uprawnień do przeglądania tej strony.</div>;
    }
    return (
        <div className='testt'>
            <div className='backbutton' onClick={() => navigate(`/adminPanel?table=desks`)}>
                <ArrowBackIcon />
            </div>
            <div className="user-edit-container">
                <h2>Dodawanie miejsca</h2>

                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>DID</label>
                        <input type="text" name="did" value={userData.did} onChange={handleChange} disabled />
                    </div>
                    <div className="input-group">
                        <label>Lokalizacja</label>

                        <select name="lokalizacja" value={userData.lokalizacja} onChange={handleChange}>
   {accessibleLocations.map(location => {
      let locationName;
      switch(location) {
         case 1: locationName = "Kraków"; break;
         case 2: locationName = "Warszawa"; break;
         case 3: locationName = "Wrocław"; break;
         case 4: locationName = "Poznań"; break;
         default: locationName = "";
      }
      return <option key={location} value={location}>{locationName}</option>;
   })}
</select>

                    </div>

                    <div className="input-group">

                        <label>Nazwa</label>
                        <input type="text" name="nazwa" value={userData.nazwa} onChange={handleChange} required />
                    </div>

                    <div className="input-group">

                        <label>Rodzaj</label>


                        <select name="rodzaj"  value={userData.rodzaj} onChange={handleChange}>
                            <option value="1">Biurko</option>
                            <option value="2">Miejsce parkingowe</option>
                        </select>



                    </div>
                    <button type="submit">Zapisz zmiany</button>
                </form>
            </div>

            {isModalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <Confirmation
                            message={modalMessage}
                            onConfirm={() => {

                                setIsModalVisible(false);
                                document.body.style.overflow = "auto";
                                navigate(`/adminPanel?table=desks`);

                            }}
                            onClose={() => {
                                setIsModalVisible(false);
                                document.body.style.overflow = "auto";
                                navigate(`/adminPanel?table=desks`);

                            }}
                        />
                    </div>
                </div>
            )}
        </div>


    );
};

export default DeskAdd;

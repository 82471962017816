import React, { useState, useEffect } from "react";
import "./ReservationConfirm.css";
import axios from "axios";
import Confirmation from "./Confirmation";
import { API_URL } from "../../config";
import ClearIcon from "@mui/icons-material/Clear";

const ReservationConfirm = ({
  desk,
  onConfirm,
  onCancel,
  testDate,
  refreshDesks,
  refreshReservations,
  refreshReservations2,
  resetSelectedDesks,
  isBlocked,
  setIsBlocked,
  forSomeone,
  forUser,
}) => {
  const [pin, setPin] = useState(["", "", "", ""]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage2, setErrorMessage2] = useState("");

  const verifyPin = async (pin, did, uid) => {
    try {
      const formData = new FormData();
      formData.append("pin", pin);
      formData.append("did", did);
      formData.append("uid", uid);
      formData.append("date", testDate);
      formData.append("forSomeone", forSomeone);
      formData.append("forUser", forUser);




      const response = await axios.post(API_URL + "/reservation", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if(response.data.error)
      {

        setShowConfirmation(true);
        setErrorMessage2(response.data.error);
        refreshDesks();

        if(forSomeone)
        {
          refreshReservations2();

        }else
        {
          refreshReservations();

        }

        resetSelectedDesks();
      }
      else
      {
        setErrorMessage2("Zarezerwowano pomyślnie.");

      }

      return {
        isPinValid: response.data.isPinValid,
        aktywny: response.data.aktywny,
      };
    } catch (error) {
      console.error("Error during PIN verification:", error);
      return false;
    }
  };

  const handleConfirm = async () => {
    try {

      const pinString = pin.join("");

      // Sprawdź, czy wszystkie pola zostały wypełnione
      if (pinString.length < 4) {
        setErrorMessage("Wypełnij wszystkie pola PINu!");
        return;
      }
      const pinInt = parseInt(pinString, 10);
      const uid = document.getElementById("uid").value;
      const result = await verifyPin(pinInt, desk.did, uid, testDate);
      const isPinValid = result.isPinValid;
      const aktywny = result.aktywny;

      if (isPinValid) {
        setShowConfirmation(true); // ustaw na true, aby wyświetlić komunikat
        //refreshData();  // Odśwież dane po potwierdzeniu
        refreshDesks();
        if(forSomeone)
        {
          refreshReservations2()
        }else
        {
          refreshReservations();

        }
        resetSelectedDesks();
      } else {
        if (aktywny >= 4) {
          setIsBlocked(true);
          refreshDesks();
          refreshReservations();
          resetSelectedDesks();
          onCancel();
        } else {
          setErrorMessage(
            "PIN jest niepoprawny! Pozostało ci " + (4 - aktywny) + " prób."
          );
          setPin(["", "", "", ""]);
          inputsRef.current[0].focus();
        }
      }
    } catch (error) {
      console.error("Error during PIN verification:", error);
    }
  };

  const handleClose = () => {
    onCancel();
  };

  useEffect(() => {
    inputsRef.current[0].focus();
  }, []);

  const getDeskName = (desk) => {
    if (desk.lokalizacja === 1) {
      return "Kraków";
    } else if (desk.lokalizacja === 2) {
      return "Warszawa";
    } else if (desk.lokalizacja === 3) {
      return "Poznań";
    } else if (desk.lokalizacja === 4) {
      return "Inny";
    } else {
      return desk.lokalizacja;
    }
  };

  const inputsRef = React.useRef([]);

  const handleInputChange = (e, index) => {
    const value = e.target.value.slice(-1);

    if (!/^[0-9]$/.test(value) && value !== "") {
      return;
    }

    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);
    onPinChange(value, index);

    if (value && index < 3) {
      inputsRef.current[index + 1].focus();
    }
  };

  const onPinChange = (value, index) => {
    setPin((prevPin) => {
      const newPin = [...prevPin];
      newPin[index] = value;
      return newPin;
    });
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (pin[index] === "" && index > 0) {
        inputsRef.current[index - 1].focus();
        const newPin = [...pin];
        newPin[index - 1] = "";
        setPin(newPin);
        onPinChange("", index - 1);
      } else {
        const newPin = [...pin];
        newPin[index] = "";
        setPin(newPin);
        onPinChange("", index);
      }
    }
    if (e.key === "Enter") {
      if (pin.every(val => val !== "")) {
        handleConfirm();
      } else {
          setErrorMessage("Wszystkie pola muszą być wypełnione.");
          setShowError(true);
      }
  }

    
  };

  useEffect(() => {
    if (errorMessage) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!showError) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [showError]);

  return (
    <div className="pin-confirm-overlay">
      {showConfirmation && (
        <Confirmation
          message={errorMessage2}
          onClose={() => {
            setShowConfirmation(false);
            onCancel();
          }}
        />
      )}
      <button className="close-button" onClick={handleClose}>
        <ClearIcon />
      </button>
      {forSomeone ? <h1>Rezerwacja dla użytkownika o ID: {forUser}</h1> : ""}
      <h2>
        Miejsce: {desk.nazwa}, {getDeskName(desk)}
      </h2>
      {testDate}
      <br />
      <br />

      <p>Potwierdź pin</p>

      <div className="error-message-container">
        <span className={`error-message ${showError ? "visible" : ""}`}>
          {errorMessage}
        </span>
      </div>

      <div className="input-container">
        {[...Array(4)].map((_, i) => (
          <input
            key={i}
            className="input-field"
            type="tel"
            maxLength={1}
            value={pin[i]}
            onChange={(e) => handleInputChange(e, i)}
            onKeyDown={(e) => handleKeyDown(e, i)}
            ref={(ref) => (inputsRef.current[i] = ref)}
            style={{ WebkitTextSecurity: "disc" }}
          />
        ))}
      </div>

      <input
        className="submit-button"
        type="submit"
        value="Zarezerwuj"
        onClick={handleConfirm}
      />
    </div>
  );
};

export default ReservationConfirm;
